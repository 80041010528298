import loadable from '@loadable/component';

import React from 'react';

import { useDispatch } from 'react-redux';

import { markAsReadFlow } from 'actions/ArtworkActions';

import FlowHeader from './Flow/Header';
import './Folder.scss';

const FlowContent = loadable(() => import('./Flow/Content'));

type Props = {
  flow: Object,
  isFull: Boolean,
  userProfile: Object,
  className: Object,
};

const FlowFolder = ({ flow, isFull, userProfile, className }: Props) => {
  const dispatch = useDispatch();

  const handleMarkAsRead = () => {
    dispatch(markAsReadFlow(flow.id));
  };

  return (
    <div className={`${className} Folder`}>
      <FlowHeader artwork={flow} userProfile={userProfile} />
      {isFull && <FlowContent artwork={flow} userProfile={userProfile} onMarkAsRead={handleMarkAsRead} />}
    </div>
  );
};

export default React.memo(FlowFolder);
